import { Link } from "gatsby";
import React from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Helmet from "react-helmet";
import styled from "styled-components";
import { Button } from "../components/Button";
import { Intro } from "../components/Intro";
import { Layout } from "../components/Layout";
import { Section } from "../components/section/Section";
import { SectionHeader } from "../components/section/SectionHeader";

const FormContainer = styled.div`
  input,
  textarea {
    padding: 10px 14px;
    border-radius: 0;
    box-shadow: none;
    font-size: 15px;
  }

  button[type="submit"] {
    background: #18d26e;
    border: 0;
    padding: 10px 30px;
    color: #fff;
    transition: 0.4s;
    cursor: pointer;
  }

  button[type="submit"]:hover {
    background: #13a456;
  }
`;

const ContactsPage: React.FC = () => {
  return (
    <Layout>
      <Helmet>
        <title>Contatti</title>
      </Helmet>
      <Intro
        title="Contatti"
        tagLine="Contattaci per avere maggiori informazioni"
      ></Intro>
      <Section>
        <p>
          Puoi contattarci per avere <strong>maggiori informazioni</strong>, per
          prendere un <strong>appuntamento</strong>, o per avere un{" "}
          <strong>preventivo gratuito</strong> indicando le dimensioni della
          stanza da arredare e un&apos;idea dei mobili da inserire.
        </p>
        <p className="mb-0">Puoi contattarci:</p>
        <ul>
          <li>
            tramite e-mail all&apos;indirizzo{" "}
            <a href="mailto:brianzarredi@libero.it">brianzarredi@libero.it</a>;
          </li>
          <li>
            tramite telefono / fax al numero{" "}
            <a href="tel:+390248301679">+39 02 48 30 16 79</a>;
          </li>
          <li>compilando il modulo seguente.</li>
        </ul>
      </Section>
      <Section withBackground>
        <SectionHeader>Modulo di contatto</SectionHeader>
        <p>
          Compila il seguente modulo con i tuoi dati e il messaggio da inviarci.
          Ricordati di inserire un tuo recapito (e-mail o numero di telefono)
          per poter essere ricontattato.
        </p>
        <FormContainer>
          <Form
            className="contactForm"
            name="contatti"
            data-netlify="true"
            method="POST"
            action="/modulo-contatti-inviato"
            netlify-honeypot="no-bot"
          >
            <p hidden>
              <label>
                Non compilare questo campo: <input name="no-bot" />
              </label>
            </p>
            <input type="hidden" name="form-name" value="contatti" />
            <Form.Row>
              <Form.Group as={Col} md="6" controlId="senderName">
                <Form.Label>Nome</Form.Label>
                <Form.Control name="nome" required></Form.Control>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="senderSurname">
                <Form.Label>Cognome</Form.Label>
                <Form.Control name="cognome" required></Form.Control>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="senderEmail">
                <Form.Label>E-mail</Form.Label>
                <Form.Control type="email" name="email"></Form.Control>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="senderTel">
                <Form.Label>Telefono</Form.Label>
                <Form.Control name="telefono"></Form.Control>
              </Form.Group>
            </Form.Row>
            <Form.Group controlId="contactMessage">
              <Form.Label>Messaggio</Form.Label>
              <Form.Control
                as="textarea"
                name="messaggio"
                rows={8}
                required
              ></Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Check custom>
                <Form.Check.Input
                  id="contactPrivacy"
                  required
                ></Form.Check.Input>
                <Form.Check.Label htmlFor="contactPrivacy">
                  Ho letto l&apos;
                  <Link to="/privacy-policy/">
                    informativa sulla privacy
                  </Link>{" "}
                  ed esprimo il consenso al trattamento dei miei dati personali
                </Form.Check.Label>
              </Form.Check>
            </Form.Group>
            <div className="text-center">
              <Button isPrimary as="button" type="submit">
                Invia
              </Button>
            </div>
          </Form>
        </FormContainer>
      </Section>
    </Layout>
  );
};

export default ContactsPage;
